<template>
  <div id="issue" class="issue">
    <div class="header pa-2 pb-5 px-4" :class="$vuetify.breakpoint.mobile ? 'pt-4' : 'pt-6'">
      <v-btn x-large color="primary" @click="pickImage" :block="$vuetify.breakpoint.mobile ? true : false">
        <img src="~@/assets/log-it-logo-opacity.png" alt="log-it" height="34px" />
        <h3 class="ml-4">Log-it</h3>
      </v-btn>
    </div>
    <div class="bg-main pt-4">
      <IssueList ref="issueList" :takeNum="25">
        <template v-slot:header>
          <h3 class="px-4">Log-it {{ $t("t_history") }}</h3>
        </template></IssueList
      >
    </div>
  </div>
</template>

<script>
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IssueService } from "@/services";
import Helpers from "@/mixins/helpers";
import { mapActions } from "vuex";
import IssueList from "@/components/issues/IssueList.vue";
import heic2any from "heic2any";

export default {
  name: "Issues",
  metaInfo: {
    title: "Log-it",
  },
  components: { IssueList },
  mixins: [Helpers],
  data: () => ({
    image: null,
    issue: null,
    issues: null,
    drawer: null,
  }),
  methods: {
    ...mapActions("issue", ["setImage"]),
    reset() {
      this.issue = null;
      this.image = null;
      this.getIssues();
    },

    async pickImage() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: this.$platform !== "web" ? CameraSource.Prompt : CameraSource.Photos,
        });

        // Check if image is HEIC (check base64 signature or format)
        if (image.base64String.startsWith("AAAA") || image.format.toLowerCase() === "heic") {
          // Convert HEIC to JPEG
          const convertedImage = await this.convertHeicToJpeg(image);
          this.image = convertedImage;
        } else {
          this.image = image;
        }

        if (this.image) {
          this.setImage(this.image);
          this.$router.push({ name: "issue" });
        }
      } catch (error) {
        console.error("Error picking images: ", error);
      }
    },

    // Add these helper methods to your component
    async convertHeicToJpeg(image) {
      try {
        // Convert base64 to blob
        const blob = this.base64ToBlob(image.base64String, "image/heic");

        // Convert HEIC to JPEG
        const jpegBlob = await heic2any({
          blob: blob,
          toType: "image/jpeg",
          quality: 0.9,
        });

        // Convert back to base64
        const jpegBase64 = await this.blobToBase64(jpegBlob);

        return {
          base64String: jpegBase64,
          format: "jpeg",
          // Preserve any other properties from original image
          ...Object.keys(image)
            .filter((key) => !["base64String", "format"].includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: image[key] }), {}),
        };
      } catch (error) {
        console.error("HEIC conversion failed:", error);
        throw error;
      }
    },

    base64ToBlob(base64, type) {
      const byteString = atob(base64);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type });
    },

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result;
          // Remove data URL prefix (e.g., "data:image/jpeg;base64,")
          resolve(base64.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async loadIssue(issueId) {
      const r = await IssueService.getIssue(issueId);
      this.issue = r.data;
      this.drawer = false;
    },

    async getIssues() {
      const r = await IssueService.getIssues();
      this.issues = r.data;
    },
  },
  mounted() {
    let params = this.$route.params;
    if (params.id) {
      this.loadIssue(params.id);
    }

    this.getIssues();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.evt) {
        vm.pickImage();
      }
      vm.prevRoute = from;
    });
  },
};
</script>
<style lang="scss">
.issue {
  .header {
    background: #eee;
    border-bottom: rgba(0, 0, 0, 0.1) 2px solid;
  }
  .theme--dark {
    .header {
      background: #272727;
      border-bottom: rgba(255, 255, 255, 0.4) 2px solid;
    }
  }
}
.theme--dark {
  .issue {
    .header {
      background: #272727;
      border-bottom: rgba(255, 255, 255, 0.4) 2px solid;
    }
  }
}
</style>
